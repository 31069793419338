
.logo {
  float: left;
}
.layout {
  height: 100%;
  overflow: scroll;
  min-width: 1200px;
}

.ant-layout-content {
  flex: none;
  height: auto
}
h1 {
  color: #333;
  font-size: 30px !important;
  padding: 15px 0 !important;
  border-bottom: 1px solid #e5e5e6;
  margin-bottom: .2rem !important;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: none !important
}

.ant-modal {
  width: 40% !important;
  min-width: 1000px;
}

.ant-layout-header {
  /* background-color: #fff; */
  padding: 0 .32rem

}
/*.ant-layout-sider-light, .ant-menu {
  background-color: #f5f5f6
} */
